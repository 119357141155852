import React, { useState, useEffect } from 'react';
import { Route, Redirect, BrowserRouter, Switch } from 'react-router-dom';
import './App.css'
import { AuthContext } from './context';
import SnackbarProvider from 'react-simple-snackbar'
import { ToastContainer, toast } from 'react-toastify';
import { AppProvider } from './AppContext';
import 'react-toastify/dist/ReactToastify.css';

function App() { 
  const [loggedIn, setLoggedIn] = useState(false);
  
  const [user, setUser] = useState({});


  const login = (user) => {
    setLoggedIn(true);
    setUser(user);
    localStorage.setItem("isLoggedIn", true)
    if (user) {
      var keys = Object.keys(user);
      keys.forEach(function (key) {
        localStorage.setItem(key, user[key])
      });
    } 
  }
  const logout = () => { 
    let userId =localStorage.getItem("userid"); 
    setLoggedIn(false); setUser({}); localStorage.clear(); 
    localStorage.setItem("userid2", userId)
  }
   
  const loading = () => <div>Loading...</div>;
  const Login = React.lazy(() => import('./components/signin/login/Login'));
  const LinkVerification = React.lazy(() => import('./components/signin/resetpassword/LinkVerification'));
  const DefaultLayout = React.lazy(() => import('./components/containers/DefaultLayout/DefaultLayout'));
  const SessionExpired = React.lazy(() => import('./components/SessionExpired/SessionExpired'));
  
  return (
    <React.Fragment>
      <ToastContainer />
      <AuthContext.Provider value={{ isLoggedIn: loggedIn, user: user, login: login, logout: logout}}> 
        <React.Suspense fallback={loading()}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/linkverification" name="Link Verification" render={props => <LinkVerification {...props} />} />
              {/* <Route exact path="/sessionexpired" name="sessionexpired"  render={props => <SessionExpired {...props} />} /> */}
              <AppProvider>
              <SnackbarProvider>
                <Route path="/" name="Dashboard" render={props => <DefaultLayout {...props}/>} />
              </SnackbarProvider> 
              </AppProvider>
            </Switch>
            
          </BrowserRouter>
        </React.Suspense> 
        
      </AuthContext.Provider >
    </React.Fragment>
   );
}

export default App;
